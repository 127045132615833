import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-614eb0c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "system-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      (_ctx.item.status.name === 'system' || _ctx.item.status.name === 'read-only')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " This data field cannot be edited or deleted, because it is a system field. "))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_tm_dropdown_item, {
              icon: "edit",
              label: "Edit",
              onClick: _ctx.openCustomFieldModal
            }, null, 8, ["onClick"]),
            _createVNode(_component_tm_dropdown_item, {
              icon: "delete",
              label: "Delete",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeleteCustomFieldModal(_ctx.item.title)))
            })
          ], 64))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}