
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import SettingsDataFieldsDropdown from '@/components/pages/contacts/settings/contact/SettingsDataFieldsDropdown.vue'

export default defineComponent({
  components: {
    TmTable,
    TmStatus,
    TmBadge,
    SettingsDataFieldsDropdown,
  },
})
