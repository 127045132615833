import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_settings_data_fields_filter = _resolveComponent("settings-data-fields-filter")!
  const _component_settings_data_fields_table = _resolveComponent("settings-data-fields-table")!
  const _component_page_block = _resolveComponent("page-block")!

  return (_openBlock(), _createBlock(_component_page_block, { breadcrumbs: _ctx.breadcrumbsLink }, {
    "page-blocks-top": _withCtx(() => [
      _createVNode(_component_details_hero, {
        icon: "tmi-view-list",
        title: "Contact data fields",
        color: "blue",
        class: "mb-4"
      })
    ]),
    "white-wrapper": _withCtx(() => [
      _createVNode(_component_settings_data_fields_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["search"]),
      _createVNode(_component_settings_data_fields_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        "items-per-page": 100,
        "items-per-page-options": [100, 250],
        "items-name": "data fields"
      }, null, 8, ["headers", "items"])
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}