
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmTableActionButton,
    TmDropdown,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      openDeleteCustomFieldModal,
    } = useContactsOpenModal()

    const { openModal } = useModals()
    const openCustomFieldModal = () => {
      openModal('customField', {
        modalTitle: 'Edit custom field',
        btnText: 'Save',
        name: props.item.title,
        type: props.item.type,
        view: props.item.view,
        modal: props.item.modal,
        viewText: 'Ticket details view',
        modalText: 'New ticket modal',
      })
    }

    return {
      openDeleteCustomFieldModal,
      openCustomFieldModal,
    }
  },
})
