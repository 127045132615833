import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b85bc64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "toggle-cell" }
const _hoisted_3 = { class: "font-weight-normal distinct--text" }
const _hoisted_4 = {
  key: 0,
  class: "toggle-cell"
}
const _hoisted_5 = { class: "font-weight-normal distinct--text" }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_settings_data_fields_dropdown = _resolveComponent("settings-data-fields-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-title": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: props.row.type.icon,
          size: "xLarge",
          class: "distinct--text mr-2"
        }, null, 8, ["name"]),
        _createTextVNode(" " + _toDisplayString(props.row.title), 1)
      ])
    ]),
    "body-cell-status": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.status)), null, 16)
    ]),
    "body-cell-view": _withCtx((props) => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_tm_field, {
          modelValue: props.row.view,
          "onUpdate:modelValue": ($event: any) => ((props.row.view) = $event),
          type: "toggle"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(props.row.view ? 'Visible' : 'Hidden'), 1)
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])
      ])
    ]),
    "body-cell-modal": _withCtx((props) => [
      (props.row.status.name !== 'read-only')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_tm_field, {
              modelValue: props.row.modal,
              "onUpdate:modelValue": ($event: any) => ((props.row.modal) = $event),
              type: "toggle"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(props.row.modal ? 'Visible' : 'Hidden'), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, " - "))
    ]),
    "body-cell-type": _withCtx((props) => [
      _createVNode(_component_tm_badge, {
        icon: props.row.type.icon,
        static: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.type.text), 1)
        ]),
        _: 2
      }, 1032, ["icon"])
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_settings_data_fields_dropdown, {
        item: props.row
      }, null, 8, ["item"])
    ]),
    _: 1
  }))
}