
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import { useModals } from '@/compositions/modals'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
  },
  setup() {
    const { openModal } = useModals()
    const search = ref('')
    const openCustomFieldModal = () => {
      openModal('customField')
    }

    return {
      search,
      openCustomFieldModal,
    }
  },
})
